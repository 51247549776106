@if (filters) {
  <aside class="font-sans transform top-0 left-0 w-80 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-20" [class.translate-x-0]="show" [class.-translate-x-full]="!show">
    <div class="absolute inset-0 overflow-hidden">
      <div class="absolute inset-0">
        <div class="fixed inset-y-0 max-w-full flex">
          <div class="w-screen max-w-md">
            <div class="h-full flex flex-col bg-white">
              <div class="pt-5 pb-4 px-4 bg-blue-500 sm:px-6 z-50">
                <div class="flex items-center justify-between">
                  <h2 class="text-lg font-medium text-white">
                    Filter Shipments
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button type="button"
                      class="bg-blue-500 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      (click)="show = false">
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative flex-1 py-6 px-4 sm:px-6">
                <div class="absolute inset-0 py-6 px-4 sm:px-6">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                      <a (click)="tabIndex = 0"
                        class="tailwind-tab text-gray-500"
                        [class.tailwind-tab-selected]="tabIndex == 0"
                        aria-current="page">
                        Filters
                      </a>
                      <a (click)="tabIndex = 1"
                        class="tailwind-tab text-gray-500"
                        [class.tailwind-tab-selected]="tabIndex == 1">
                        Columns
                      </a>
                    </nav>
                  </div>
                  @if (tabIndex == 0) {
                    <div>
                      <button class="items-center mt-2 mb-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full" (click)="openFilterModal()">Add Filter</button>
                      @for (filter of filters; track filter.id) {
                        <div [class.bg-gray-100]="isFilterSelected(filter.id)" [class.rounded-none]="isFilterSelected(filter.id)" class="flex justify-between hover:bg-sky-100 rounded-lg pl-1 p-2 cursor-pointer text-sm" (click)="toggleFilter(filter)">
                          <div class="flex items-center">
                            @if (filter.isFavorite) {
                              <mat-icon class="mr-2 fav" svgIcon="mat_outline:star" [matTooltip]="filter.isSystem ? 'Built-in Filters cannot be toggled' : 'Remove from Favorites'" (click)="toggleFavorite($event, filter)"></mat-icon>
                            }
                            @if (!filter.isFavorite) {
                              <mat-icon class="mr-2" svgIcon="mat_outline:star_border" [matTooltip]="filter.isSystem ? 'Built-in Filters cannot be toggled' : 'Add to Favorites'" (click)="toggleFavorite($event, filter)"></mat-icon>
                            }
                            <span>{{filter.name}}</span>
                          </div>
                          @if (!filter.isSystem || isDeveloper) {
                            <mat-icon #trigger="matMenuTrigger" [matMenuTriggerFor]="menu" (click)="toggleFilterMenu($event, trigger)">more_vert</mat-icon>
                          }
                          <mat-menu #menu="matMenu">
                            <ng-template matMenuContent>
                              <button mat-menu-item (click)="editFilter(filter)">
                                <span>Edit</span>
                              </button>
                              <button mat-menu-item (click)="deleteFilter(filter)">
                                <span>Delete</span>
                              </button>
                              <button mat-menu-item (click)="scheduleNotification(filter)">
                                <span>Schedule Email</span>
                              </button>
                            </ng-template>
                          </mat-menu>
                        </div>
                      }
                    </div>
                  }
                  @if (tabIndex == 1) {
                    <div>
                      <button class="items-center mt-2 mb-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full" (click)="onResetSelectedColumns()">Reset Columns</button>
                      <div cdkDropList (cdkDropListDropped)="dropColumn($event)">
                        @for (column of columns; track column) {
                          <div class="flex justify-between -ml-3 p-1 pb-0 cursor-pointer items-center" cdkDrag>
                            <div class="flex items-center">
                              <mat-checkbox [checked]="column.selected" color="primary" name="selected-{{column.columnName}}" (change)="columnToggled.emit(column)">{{column.label}}</mat-checkbox>
                            </div>
                            <mat-icon svgIcon="mat_solid:drag_indicator" class="cursor-move" matTooltip="Drag to move" cdkDragHandle></mat-icon>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
}
@if (!show) {
  <div id="filter-icon-wrapper"
    class="filter-button"
    [class.custom-bounce]="showBounceAnimation"
    matTooltip="Apply Filters / Customize Columns"
    matTooltipPosition="right"
    (click)="open()">
    <mat-icon id="filter-icon" class="text-white">filter_alt</mat-icon>
  </div>
}
