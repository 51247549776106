import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { CarrierSearchDto } from '../../../../core/services/api.service';
import { CarrierSearchComponent } from '../../../carrier-search/carrier-search.component';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-carrier-grid-filter',
    templateUrl: './carrier-grid-filter.component.html',
    styleUrls: ['./carrier-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, CarrierSearchComponent]
})
export class CarrierGridFilterComponent extends GridFilterBaseComponent {

  carrier: Array<CarrierSearchDto> = [];

  constructor() {
    super();
  }

  carrierSelected(carriers: Array<CarrierSearchDto>) {
    this.carrier = carriers;
    this.filterItem.value1 = this.carrier.map(c => c.id).join(",");
  }

  getPreselectedCarrierIds(): number[] {
    let carrierIds: number[] = [];

    if (this.filterItem.value1) {
      this.filterItem.value1.split(',').map((x) => {
        carrierIds.push(parseInt(x));
      })
    }

    return carrierIds;
  }

}

