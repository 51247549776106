import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { CustomerSelectorDto } from '../../../../core/services/api.service';
import { CustomerSearchComponent } from '../../customer-search/customer-search.component';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-customer-grid-filter',
    templateUrl: './customer-grid-filter.component.html',
    styleUrls: ['./customer-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, CustomerSearchComponent]
})
export class CustomerGridFilterComponent extends GridFilterBaseComponent {

  constructor() {
    super();
  }

  customers: Array<CustomerSelectorDto> = [];

  ngOnInit(): void {
    if(this.filterItem.filterType == this.filterTypes.Equals) {
      this.filterItem.filterType = this.filterTypes.Contains;
    }
  }

  customerSelected(customers: Array<CustomerSelectorDto>) {
    this.customers = customers;
    this.filterItem.value1 = this.customers.map(c => c.id).join(",");
  }

  getCustomerIDs() {
    return !(this.filterItem.value1 == '' || this.filterItem.value1 == null || this.filterItem.value1 == undefined) ? this.filterItem.value1.split(',').map(m => parseInt(m)) : []
  }
}
