import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { FilterDto, FilterTypeEnum, FilterItemDto, FiltersClient, ColumnTypeEnum, GridColumnDto, ListTypeEnum } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TextGridFilterComponent } from '../../widgets/grid-filters/text-grid-filter/text-grid-filter.component';
import { BooleanGridFilterComponent } from '../../widgets/grid-filters/boolean-grid-filter/boolean-grid-filter.component';
import { SelectGridFilterComponent } from '../../widgets/grid-filters/select-grid-filter/select-grid-filter.component';
import { NumberGridFilterComponent } from '../../widgets/grid-filters/number-grid-filter/number-grid-filter.component';
import { CarrierGridFilterComponent } from '../../widgets/grid-filters/carrier-grid-filter/carrier-grid-filter.component';
import { CustomerGridFilterComponent } from '../../widgets/grid-filters/customer-grid-filter/customer-grid-filter.component';
import { CurrencyGridFilterComponent } from '../../widgets/grid-filters/currency-grid-filter/currency-grid-filter.component';
import { DateGridFilterComponent } from '../../widgets/grid-filters/date-grid-filter/date-grid-filter.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
    templateUrl: './grid-filter-modal.component.html',
    styleUrls: ['./grid-filter-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CdkDrag, CdkDragHandle, FormsModule, MatFormFieldModule, MatInputModule, DateGridFilterComponent, CurrencyGridFilterComponent, CustomerGridFilterComponent, CarrierGridFilterComponent, NumberGridFilterComponent, SelectGridFilterComponent, BooleanGridFilterComponent, TextGridFilterComponent, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class GridFilterModalComponent implements OnInit {
  filter: FilterDto = FilterDto.fromJS({
    filterItems: []
  });
  columns: GridColumnDto[] = [];

  // enum fields
  filterTypes = FilterTypeEnum;
  public columnTypes = ColumnTypeEnum;

  // locals
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: GridFilterData,
    protected requestService: RequestService,
    protected dialogRef: MatDialogRef<GridFilterModalComponent>,
    protected filterClient: FiltersClient)
  {
    if(data.columns) {
      this.columns = data.columns;
    }

    if(data.filter) {
      this.filter = data.filter;
    }
  }

  ngOnInit(): void {
    if(!this.filter.id) {
      this.filter.listType = ListTypeEnum.Shipments;
      this.addFilterItem();
    }
  }

  addFilterItem() {
    let index = this.filter?.filterItems?.length ?? 0;
    let newIndex = index == 0 ? 0 : (index * -1);
    let newItem = FilterItemDto.fromJS({id: newIndex});

    this.filter?.filterItems?.push(newItem);
  }

  deleteFilterItem(id: number) {
    const index = this.filter?.filterItems?.findIndex(x => x.id == id) ?? -1;

    if (index > -1) {
      this.filter?.filterItems?.splice(index, 1);
    }
  }

  getItemColumnType(columnName: string) {
    return this.columns.find(x => x.columnName == columnName);
  }

  saveFilter() {
    let operation: Observable<any>;

    this.filter?.filterItems?.forEach(x => {
      if(x.id < 0) {
        x.id = 0;
      }
    })

    if(this.filter.id) {
      operation = this.filterClient.filters_Put(this.filter.id, this.filter);
    } else {
      if (this.data.listType) {
        this.filter.listType = this.data.listType;
      }
      operation = this.filterClient.filters_Post(this.filter)
    }

    this.requestService.save(operation, x => {
      this.dialogRef.close(x || this.filter)
    }, "Filter");
  }

  trackById(index: number, item: FilterItemDto) {
    return index + (''+item.id);
  }
}

export class GridFilterData {
  filter: FilterDto | undefined;
  columns: GridColumnDto[] = [];
  listType: ListTypeEnum | undefined;
}
