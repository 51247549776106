<div class="bg-white min-h-full">
    <div class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div class="max-w-max mx-auto">
            <main class="sm:flex">
            <p class="text-4xl font-extrabold text-blue-600 sm:text-5xl"><i class="fas fa-search"></i></p>
            <div class="sm:ml-6">
                <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                    <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">No Columns Selected</h1>
                    <p  class="mt-1 text-base text-gray-500"><button class="link" (click)="onFilter()">Click here</button> to reset to default view</p>
                </div>
            </div>
            </main>
        </div>
    </div>
</div>