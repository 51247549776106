import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'sn-material-grid-empty-columns',
    templateUrl: './material-grid-empty-columns.component.html',
    styleUrls: ['./material-grid-empty-columns.component.scss'],
    standalone: true
})
export class MaterialGridEmptyColumnsComponent implements OnInit {

  @Output() filter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  
  onFilter() {
    this.filter.emit();
  }
}
