<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" required (selectionChange)="columnChanged($event)">
    @for (column of columns; track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" name="filterType-{{filterItem.id}}" required>
    <mat-option [value]="filterTypes.Equals">Equals</mat-option>
    <mat-option [value]="filterTypes.DoesNotEqual">Does Not Equal</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Value</mat-label>
  <mat-select [(ngModel)]="filterItem.value1" name="value1-{{filterItem.id}}" required>
    <mat-option [value]="'true'">True</mat-option>
    <mat-option [value]="'false'">False</mat-option>
  </mat-select>
</mat-form-field>