
import { Component, Input, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { ColumnTypeEnum, FilterItemDto, FilterTypeEnum, GridColumnDto } from "../../../core/services/api.service";

@Component({
    template: '',
})
export abstract class GridFilterBaseComponent {
    
    @Input() id: string;
    @Input() filterItem: FilterItemDto;
    @Input() columns: GridColumnDto[];

    columnTypes = ColumnTypeEnum;
    filterTypes = FilterTypeEnum;

    columnChanged(event: MatSelectChange) {
        let column = this.columns.find(x => x.columnName == event.value);

        this.filterItem.column = column.columnName;
        this.filterItem.columnType = column.columnType;
        this.filterItem.value1 = null;
        this.filterItem.value2 = null;
        this.filterItem.meta = null;
        this.filterItem.filterType = FilterTypeEnum.Equals;
    }
}
