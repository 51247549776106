import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-text-grid-filter',
    templateUrl: './text-grid-filter.component.html',
    styleUrls: ['./text-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, MatInputModule]
})
export class TextGridFilterComponent extends GridFilterBaseComponent {
  constructor() {
    super();
  }
}