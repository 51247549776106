import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-number-grid-filter',
    templateUrl: './number-grid-filter.component.html',
    styleUrls: ['./number-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, MatInputModule, NgxMaskDirective],
    providers: [ provideNgxMask() ]
})
export class NumberGridFilterComponent extends GridFilterBaseComponent {

  constructor() {
    super();
  }
}
