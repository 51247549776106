<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" required (selectionChange)="columnChanged($event)">
    @for (column of columns; track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" name="filterType-{{filterItem.id}}" required>
    <mat-option [value]="filterTypes.Contains">Contains</mat-option>
    <mat-option [value]="filterTypes.DoesNotContain">Does Not Contain</mat-option>
  </mat-select>
</mat-form-field>
<sn-customer-search class="flex flex-100 translateYN10" [multi]=true label="Value" [customerIDs]="getCustomerIDs()" (customers)="customerSelected($event);"></sn-customer-search>
<input [ngModel]="filterItem.value1" name="customerIds_hidden" required type="hidden">
