<h2 matDialogTitle
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
cdkDragHandle>{{filter.id ? 'Edit' : 'Add'}} Filter</h2>
<mat-dialog-content>
  @if (filter.isSystem) {
    <div class="rounded-md bg-red-50 p-4 mb-3">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">Warning: This is a System query, any changes made here will affect all users</h3>
        </div>
      </div>
    </div>
  }
  <form #filterForm="ngForm">
    <mat-form-field class="w-full">
      <mat-label>Filter Name</mat-label>
      <input matInput name="value" [(ngModel)]="filter.name" required maxlength="50" />
    </mat-form-field>
    @for (filterItem of filter.filterItems; track trackById($index, filterItem)) {
      <div class="flex gap-2 items-baseline">
        @switch (filterItem.columnType) {
          @case (columnTypes.Date) {
            <sn-date-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-date-grid-filter>
          }
          @case (columnTypes.Currency) {
            <sn-currency-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-currency-grid-filter>
          }
          @case (columnTypes.Customer) {
            <sn-customer-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row flex-wrap"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-customer-grid-filter>
          }
          @case (columnTypes.Carrier) {
            <sn-carrier-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row flex-wrap"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-carrier-grid-filter>
          }
          @case (columnTypes.Number) {
            <sn-number-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-number-grid-filter>
          }
          @case (columnTypes.Select) {
            <sn-select-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [id]="filterItem.column + $index"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-select-grid-filter>
          }
          @case (columnTypes.SelectNumeric) {
            <sn-select-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [id]="filterItem.column + $index"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-select-grid-filter>
          }
          @case (columnTypes.Flags) {
            <sn-select-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [id]="filterItem.column + $index"
              [isFlags]="true"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-select-grid-filter>
          }
          @case (columnTypes.Boolean) {
            <sn-boolean-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-boolean-grid-filter>
          }
          @default {
            <sn-text-grid-filter
              class="flex flex-1 gap-2 flex-col md:flex-row"
              [filterItem]="filterItem"
              [columns]="columns">
            </sn-text-grid-filter>
          }
        }

        @if (filter.filterItems.length > 1) {
          <mat-icon svgIcon="heroicons_outline:trash" class="cursor-pointer hover:text-red-700" (click)="deleteFilterItem(filterItem.id)" matTooltip="Delete Criteria"></mat-icon>
        }
      </div>
    }
    <button [disabled]="!filterForm.valid" mat-stroked-button class="w-full" (click)="addFilterItem()">
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus'"></mat-icon>
      <span class="ml-2">Add</span>
    </button>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button [color]="filter.isSystem ? 'warn': 'primary'" [disabled]="!filterForm.valid" (click)="saveFilter()">Save</button>
</mat-dialog-actions>
